<template>
  <CContainer class="d-flex align-items-center min-vh-100 justify-content-center">
    <CRow>
      <CCol md="12">
        <CCardGroup>
          <CCard class="p-4">
            <CCardBody>
              <CForm v-on:keyup.13="login">
                <h1>Login</h1>
                <p class="text-muted">Melden Sie sich bei Ihrem Konto an</p>
                <CInput
                        v-model="email"
                        placeholder="E-Mail (oder Benutzername)"
                        autocomplete="email username"
                >
                  <template #prepend-content>
                    <CIcon name="cil-user"/>
                  </template>
                </CInput>
                <CInput
                        v-model="password"
                        placeholder="Passwort"
                        type="password"
                        autocomplete="current-password"
                >
                  <template #prepend-content>
                    <CIcon name="cil-lock-locked"/>
                  </template>
                </CInput>
                <CRow>
                  <CCol col="6">
                    <CButton @click="login" color="primary" class="px-4">Login</CButton>
                  </CCol>
                  <CCol col="6" class="text-right">
                    <CButton to="forgotPassword" color="link" class="px-0">Passwort vergessen</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
          <CCard
                  color="primary"
                  text-color="white"
                  class="text-center py-5 d-md-down-none"
          >

            <CCardBody>
                <CImg v-if="!loggingIn" src="img/brand/cloud-prt-logo-white.svg" style="width: 20%"/>
                <h2>Kostenlosen Account erstellen</h2>
                <p> Schließen Sie sich 900+ Immobilienprofis an</p>
                <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                  href="https://www.makler-anfragen.immo/kostenfrei-testen/"
                >
                  Kostenfrei registrieren
                </CButton>
              </CCardBody>
          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import axios from 'axios'
import commons from '../../commons'

export default {
  name: 'Login',
  data() {
    return {
      loggingIn: false,
      email: this.$route.params.email || '',
      password: ''
    }
  },
  methods: {
    addToast(header, content) {
      this.$toaster.makeToast(header, content)
    },
    login() {
      this.loggingIn = true
      axios.post('/user/login', {email: this.email, password: this.password})
          .then(response => {
            commons.setToStorage('jwtToken', response.data.token)
            commons.setToStorage('loggedIn', true)
            commons.setToStorage('currentRealm', response.data.realm)
            commons.setToStorage('license', response.data.realm.license)
            commons.setToStorage('currentUser', response.data.user)
            commons.setToStorage('currentOrganization', response.data.user.organization)
            commons.setToStorage('unlayerSignatureHmac', response.data.unlayerSignatureHmac)
            const redirectAfterLogin = commons.getFromStorage('redirectAfterLogin')
           if (typeof redirectAfterLogin === 'string') {
              commons.removeFromStorage('redirectAfterLogin')
              window.location.replace(redirectAfterLogin)
            } else {
              this.$router.push({name: 'Home'})
            }
          }).catch(err => {
            if (err.response) {
              switch (err.response.status) {
                case 404:
                  this.addToast('Oops!', `<b>Konnten Sie nicht einloggen.</b><br>Der angegebene Benutzer existiert nicht.`)
                  break
                case 406:
                  this.addToast('Oops!', `<b>Konnten Sie nicht einloggen.</b><br>Bitte legen Sie erst Ihr Passwort fest. Sie haben dazu bereits eine E-Mail erhalten.`)
                  break
                case 422:
                  this.addToast('Oops!', `<b>Konnten Sie nicht einloggen.</b><br>Die angegebenen Zugangsdaten stimmen nicht überein.`)
                  break
              }
            }
            console.error(err)
            this.loggingIn = false
          })
    }
  }
}
</script>
